const React = require("react");
const api = require("../api");
const { useState, useEffect, createContext, useContext, useCallback, useMemo } = React;

const PopupContext = createContext(null);

const PER_PAGE = 50;

function calcOffset(page) {
  return (page - 1) * PER_PAGE;
}

function Modal({ children }) {
  const { handleClosePopup } = useContext(PopupContext);
  return <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }}
    onClick={(evt) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (evt.target !== evt.currentTarget) {
        return;
      }

      handleClosePopup();
    }}>
    {children}
  </div>
}

function generateIdemKey() {
  return "idem-" + Math.random().toString(36).substring(2, 15);
}

function TxListPopup({ partnerID, partnerName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [txs, setTxs] = useState([]);
  const [memo, setMemo] = useState("");
  const [amount, setAmount] = useState("");
  const [idemKey, setIdemKey] = useState(generateIdemKey());

  const handleLoad = async () => {
    setIsLoading(true);
    const resp = await api.get(`/api/partners/${partnerID}/txs`);
    setTxs(resp.items);
    setIsLoading(false);
  }

  const handleAddTx = async () => {
    if (!confirm(`${amount} 만큼 지급하시겠습니까?`)) {
      return;
    }

    setIsRequesting(true);
    const { err } = await api.post(`/api/partners/${partnerID}/txs`, {
      amount: `${amount}`,
      idem_key: idemKey,
      memo
    });

    if (err) {
      alert(JSON.stringify(err, null, 2));
    }

    handleLoad();
    setAmount("");
    setMemo("");
    setIdemKey(generateIdemKey());
    setIsRequesting(false);
  }

  useEffect(() => {
    handleLoad();
  }, []);

  return <Modal>
    <div style={{ backgroundColor: "white", padding: "20px", width: "fit-content", opacity: isLoading ? 0.6 : 1, pointerEvents: isLoading ? "none" : "auto", borderRadius: "10px" }}>
      <h3>{`${partnerName}(${partnerID}) 적립/지급 내역`}</h3>
      <table className="pure-table pure-table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>생성일</th>
            <th>유형</th>
            <th style={{ textAlign: "right" }}>금액</th>
            <th>피추천인</th>
            <th>메모</th>
            <th>액션</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              -
            </td>
            <td>
              -
            </td>
            <td>
              지급
            </td>
            <td>
              <input style={{ textAlign: "right" }} type="number" value={amount} onChange={(evt) => setAmount(evt.target.value)} placeholder="지급액" disabled={isRequesting}></input>
            </td>
            <td>
              -
            </td>
            <td>
              <textarea value={memo} onChange={(evt) => setMemo(evt.target.value)} disabled={isRequesting}></textarea>
            </td>
            <td>
              <button className="pure-button" onClick={handleAddTx} disabled={isRequesting}>추가</button>
            </td>
          </tr>
          {txs.map((tx) => (
            <tr key={tx.id}>
              <td>{tx.id}</td>
              <td>{new Date(tx.created_time).toLocaleString()}</td>
              <td>{tx.type === "paid" ? "지급" : "적립"}</td>
              <td style={{ textAlign: "right" }}>{tx.amount}</td>
              <td>{tx.referred_user_name ? `${tx.referred_user_name}(${tx.referred_user_id})` : "-"}</td>
              <td>{tx.memo ? tx.memo : "-"}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Modal>;
}

function copyPartnerLink(code) {
  const link = `https://ref.boxhero-app.com/${code}`;
  navigator.clipboard.writeText(link);
  alert(`파트너 링크가 복사되었습니다.\n${link}`);
}

function NewPartnerPopup() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (!name || !email) {
      alert("이름과 이메일을 입력해주세요.");
      return;
    }

    if (!confirm(`${name} ${email} 을 등록하시겠습니까?`)) {
      return;
    }

    setIsRequesting(true);
    const { resp, err } = await api.post(`/api/partners`, {
      name,
      email
    });

    if (err) {
      alert(JSON.stringify(err, null, 2));
    }

    copyPartnerLink(resp.code);
    setName("");
    setEmail("");
    setIsRequesting(false);
  }

  return <Modal>
    <div style={{ backgroundColor: "white", padding: "20px", width: "fit-content", borderRadius: "10px" }}>
      <h3>파트너 등록</h3>
      <form className="pure-form" style={{ display: "flex", flexDirection: "column", gap: "10px", width: "300px" }}>
        <input className="pure-input-rounded" type="text" placeholder="이름" disabled={isRequesting} value={name} onChange={(evt) => setName(evt.target.value)} />
        <input className="pure-input-rounded" type="email" placeholder="이메일" disabled={isRequesting} value={email} onChange={(evt) => setEmail(evt.target.value)} />
        <button type="submit" className="pure-button pure-button-primary" disabled={isRequesting} onClick={handleSubmit}>등록</button>
      </form>
    </div>
  </Modal>;
}

function Popups() {
  const { popup } = useContext(PopupContext);

  if (popup == null) {
    return null;
  }

  if (popup.key === "tx-list") {
    return <TxListPopup partnerID={popup.payload.partnerID} partnerName={popup.payload.partnerName} />;
  } else if (popup.key === "new-partner") {
    return <NewPartnerPopup />;
  }

  return <></>;
}

function PartnerList() {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("code");
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [popup, setPopup] = useState(null);

  const handleLoad = async (page) => {
    setIsLoading(true);
    const resp = await api.get("/api/partners", {
      offset: calcOffset(page),
      limit: PER_PAGE,
      [filter]: query,
    })
    setPage(page);
    setIsLoading(false);
    setPartners(resp.items);
  }

  const handleSearch = (evt) => {
    evt.preventDefault();
    handleLoad(1);
  }

  const handleOpenPopup = (key, payload) => {
    setPopup({ key, payload });
  }

  const handleClosePopup = () => {
    setPopup(null);
    handleLoad(page);
  }

  const handleClickPrev = () => {
    const newPage = Math.max(1, page - 1);
    handleLoad(newPage);
  }

  const handleClickNext = () => {
    const newPage = page + 1;
    handleLoad(newPage);
  }

  useEffect(() => {
    handleLoad(1);
  }, []);

  return (
    <PopupContext.Provider value={{ popup, handleOpenPopup, handleClosePopup }}>
      <div>
        <form className="pure-form">
          <select value={filter} onChange={(evt) => setFilter(evt.target.value)}>
            <option value="code">추천코드</option>
            <option value="name">이름</option>
            <option value="email">이메일</option>
          </select>
          <input type="text" className="pure-input-rounded" value={query} onChange={(evt) => setQuery(evt.target.value)} />
          <button type="submit" className="pure-button" onClick={handleSearch}>검색</button>
          <button className="pure-button pure-button-primary" style={{ marginLeft: "10px" }} onClick={() => handleOpenPopup("new-partner", {})}>신규 등록</button>
          {isLoading &&
            (<img
              src="https://upload.wikimedia.org/wikipedia/commons/c/cd/Vector_Loading_fallback.gif"
              style={{ verticalAlign: "middle" }}
              alt="loading" />)}

        </form>
        <table className="pure-table pure-table-bordered">
          <thead>
            <tr className="stickyHeader">
              <th>#</th>
              <th>생성일</th>
              <th>이름</th>
              <th>이메일</th>
              <th>추천코드</th>
              <th>총 적립금</th>
              <th>지급액</th>
              <th>잔여 적립금</th>
              <th>내역</th>
            </tr>
          </thead>
          <tbody>
            {partners.map((partner, idx) => (
              <tr key={partner.id}>
                <td>{partner.id}</td>
                <td>{new Date(partner.created_time).toLocaleString()}</td>
                <td>{partner.name}</td>
                <td>{partner.email}</td>
                <td>
                  <span style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }} onClick={() => copyPartnerLink(partner.code)}>
                    {partner.code}
                  </span>
                </td>
                <td>{parseFloat(partner.total_pending_rewards).toLocaleString()}</td>
                <td>{parseFloat(partner.total_paid_rewards).toLocaleString()}</td>
                <td>{parseFloat(partner.total_amount).toLocaleString()}</td>
                <td><span onClick={() => handleOpenPopup("tx-list", { partnerID: partner.id, partnerName: partner.name })} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>내역 보기</span></td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: "flex", gap: "10px" }}>
          <button className="pure-button" onClick={handleClickPrev} disabled={page === 1}>이전 페이지</button>
          <button className="pure-button" onClick={handleClickNext} disabled={partners.length < PER_PAGE}>다음 페이지</button>
        </div>
      </div>
      <Popups />
    </PopupContext.Provider>
  );
}

module.exports = PartnerList;
